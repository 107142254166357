/* eslint-disable no-process-env */

// TODO - the process.env keys now exactly match the config keys except prefixed - can auto add them to avoid mistakes

export const num = (val) => (val == null ? val : parseInt(val, 10));
export const bool = (val) => (val == null ? val : val.toString() === 'true');

// we're using js so we can pull it more easily into webpack
// Update Config.tsx with types to match this
export default {
  ALLOW_IN_SESSION_DEBUG: {
    value: bool(process.env.REACT_APP_ALLOW_IN_SESSION_DEBUG === 'true'),
    default: false,
  },
  AUTH_URL: {
    value: process.env.REACT_APP_AUTH_URL,
  },
  AUTH_CLIENT_ID: {
    value: process.env.REACT_APP_AUTH_CLIENT_ID,
  },
  AUTH_CLIENT_SECRET: {
    value: process.env.REACT_APP_AUTH_CLIENT_SECRET,
  },
  CONNECT_APOLLO_DEV_TOOLS: {
    value: bool(process.env.REACT_APP_CONNECT_APOLLO_DEV_TOOLS === 'true'),
    default: false,
  },
  APP_TYPE: {
    value: process.env.REACT_APP_APP_TYPE,
  },
  VERSION: {
    value: process.env.REACT_APP_VERSION,
  },
  WORKOUT_API_URL: {
    value: process.env.REACT_APP_WORKOUT_API_URL,
  },
  SUBSCRIPTION_API_URL: {
    value: process.env.REACT_APP_SUBSCRIPTION_API_URL,
    default: null,
  },
  SUBSCRIPTIONS_MINIMUM_TOKEN_TTL_SECONDS: {
    value: process.env.REACT_APP_SUBSCRIPTIONS_MINIMUM_TOKEN_TTL_SECONDS,
    default: 60 * 60,
  },
  SENTRY_ENVIRONMENT: {
    value: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    default: 'dev',
  },
  LESSON_INSTANCE_UPDATES_URL: {
    value: process.env.REACT_APP_LESSON_INSTANCE_UPDATES_URL,
    default: null,
  },
  PRE_CACHE_DASH_ASSETS: {
    value: bool(process.env.REACT_APP_PRE_CACHE_DASH_ASSETS === 'true'),
    default: false,
  },
  CACHE_DASH_ASSET_QUALITY: {
    value: process.env.REACT_APP_CACHE_DASH_ASSET_QUALITY,
    default: 'LOWEST',
  },
  POST_CLASS_DISPLAY_SECONDS: {
    value: num(process.env.REACT_APP_POST_CLASS_DISPLAY_SECONDS),
    default: 60,
  },
  IDLE_OR_ERROR_DISPLAY_SECONDS: {
    value: num(process.env.REACT_APP_IDLE_OR_ERROR_DISPLAY_SECONDS),
    default: 5,
  },
  REMOTE_LOGIN_WEBSOCKET_URL: {
    value: process.env.REACT_APP_REMOTE_LOGIN_WEBSOCKET_URL,
  },
  REMOTE_LOGIN_PIN_BUFFER: {
    value: num(process.env.REACT_APP_REMOTE_LOGIN_PIN_BUFFER),
    default: 20,
  },
  INITIAL_LOAD_LOGO_ID: {
    value: process.env.REACT_APP_INITIAL_LOAD_LOGO_ID,
    default: '__initial-load-logo',
  },
  INITIAL_LOAD_TRANSITION_SECONDS: {
    value: process.env.REACT_APP_INITIAL_LOAD_TRANSITION_SECONDS,
    default: 0.8,
  },
  VIDEO_TRAILING_BUFFER_SECONDS: {
    value: num(process.env.REACT_APP_VIDEO_TRAILING_BUFFER_SECONDS),
    default: 6,
  },
  VIDEO_MINIMUM_LEADING_BUFFER_SECONDS: {
    value: num(process.env.REACT_APP_VIDEO_MINIMUM_LEADING_BUFFER_SECONDS),
    default: 6,
  },
  VIDEO_MAXIMUM_LEADING_BUFFER_SECONDS: {
    value: num(process.env.REACT_APP_VIDEO_MAXIMUM_LEADING_BUFFER_SECONDS),
    default: 120,
  },
  VIDEO_BUFFERING_TIMEOUT: {
    value: num(process.env.REACT_APP_VIDEO_BUFFERING_TIMEOUT),
    default: 60,
  },
  VIDEO_MAX_RESOLUTION_HEIGHT: {
    value: num(process.env.REACT_APP_VIDEO_MAX_RESOLUTION_HEIGHT),
    default: 1080,
  },
  // In Bit/Sec
  // Relates to <Representation bandwidth="7168000"> found in mpd manifest files (highest i've seen is ~7m)
  VIDEO_DEFAULT_BANDWIDTH_ESTIMATE: {
    value: num(process.env.REACT_APP_VIDEO_DEFAULT_BANDWIDTH_ESTIMATE),
    default: 25000000,
  },
  MONITOR_WORKOUT_VIDEO: {
    value: bool(process.env.REACT_APP_MONITOR_WORKOUT_VIDEO === 'true'),
    default: false,
  },
  // TODO might be nice to separate into tgg/sky/amazon instead of one
  MUX_DATA_ENV_KEY: {
    value: process.env.REACT_APP_MUX_DATA_ENV_KEY,
    // Needs a key to pass validation if MONITOR_WORKOUT_VIDEO=false
    default: null,
  },
  VIDEO_STALL_SKIP_SECONDS: {
    value: process.env.REACT_APP_VIDEO_STALL_SKIP_SECONDS,
    default: 0,
  },
  SENTRY_DSN: {
    value: process.env.REACT_APP_SENTRY_DSN,
    default: null,
  },
  AMAZON_RVS_HANDLER_URL: {
    value: process.env.REACT_APP_AMAZON_RVS_HANDLER_URL,
    default: '',
  },
  FLAG_SIGN_UP_FLOW_ENABLED_DEFAULT: {
    value: bool(process.env.REACT_APP_FLAG_SIGN_UP_FLOW_ENABLED_DEFAULT),
    default: false,
  },
  API_ENVIRONMENT: {
    value: process.env.REACT_APP_API_ENVIRONMENT,
    default: 'staging',
  },
  FLAG_IN_CLASS_ANIMATIONS_ENABLED_DEFAULT: {
    value: bool(process.env.REACT_APP_FLAG_IN_CLASS_ANIMATIONS_ENABLED_DEFAULT),
    default: false,
  },
  FLAG_IN_CLASS_TRANSITIONS_ENABLED_DEFAULT: {
    value: bool(process.env.REACT_APP_FLAG_IN_CLASS_TRANSITIONS_ENABLED_DEFAULT),
    default: true,
  },
  PLATFORM_RCU_BACK_BUTTON: {
    value: process.env.REACT_APP_PLATFORM_RCU_BACK_BUTTON,
    default: 'RCUBack',
  },
  CHOOSE_MEDIA_OPTION_BUTTON: {
    value: process.env.REACT_APP_CHOOSE_MEDIA_OPTION_BUTTON,
    default: 'MediaFastForward',
  },
  FLAG_CLASS_DETAILS_EXTRA_ENABLED_DEFAULT: {
    value: bool(process.env.REACT_APP_FLAG_CLASS_DETAILS_EXTRA_ENABLED_DEFAULT),
    default: false,
  },
  FLAG_MAT_CONNECTIVITY_FLOW_ENABLED_DEFAULT: {
    value: bool(process.env.REACT_APP_FLAG_MAT_CONNECTIVITY_FLOW_ENABLED_DEFAULT),
    format: Boolean,
    default: false,
  },
  FLAG_CK_CONNECTIVITY_FLOW_ENABLED_DEFAULT: {
    value: bool(process.env.REACT_APP_FLAG_CK_CONNECTIVITY_FLOW_ENABLED_DEFAULT),
    format: Boolean,
    default: false,
  },
  FLAG_DYNAMIC_HERO_TRANSITION_ENABLED_DEFAULT: {
    value: bool(process.env.REACT_APP_FLAG_DYNAMIC_HERO_TRANSITION_ENABLED_DEFAULT),
    format: Boolean,
    default: false,
  },
  FLAG_RECORDING_MODE_ENABLED_DEFAULT: {
    value: bool(process.env.REACT_APP_FLAG_RECORDING_MODE_ENABLED_DEFAULT),
    format: Boolean,
    default: false,
  },
  SCREEN_IDLE_TIME_SECS: {
    value: num(process.env.REACT_APP_SCREEN_IDLE_TIME_SECS),
    default: 900, // 15 mins
  },
  SCREEN_SAVER_ENABLED_DEFAULT: {
    value: bool(process.env.REACT_APP_SCREEN_SAVER_ENABLED_DEFAULT),
    default: false,
  },
  LOGGER_STREAM_NAME: {
    value: process.env.REACT_APP_LOGGER_STREAM_NAME,
    default: '',
  },
  LOGGER_URL: {
    value: process.env.REACT_APP_LOGGER_URL,
    default: '',
  },
  LOGGER_LEVEL: {
    value: process.env.REACT_APP_LOGGER_LEVEL,
    default: '',
  },
  MAGIC_AUTH_URL: {
    value: process.env.REACT_APP_MAGIC_AUTH_URL,
  },
  WEB_APP_BETA_FEEDBACK_TYPEFORM_URL: {
    value: process.env.REACT_APP_WEB_APP_BETA_FEEDBACK_TYPEFORM_URL,
    default: '',
  },
  BETA_FEEDBACK_SURVEY_RETAKE_DAYS: {
    value: num(process.env.REACT_APP_BETA_FEEDBACK_SURVEY_RETAKE_DAYS),
    default: 30,
  },
  BETA_FEEDBACK_MODAL_MAX_DISMISSAL_COUNT: {
    value: num(process.env.REACT_APP_BETA_FEEDBACK_MODAL_MAX_DISMISSAL_COUNT),
    default: 5,
  },
  VIDEO_INCREASED_PLAYBACK_SPEED: {
    value: process.env.REACT_APP_VIDEO_INCREASED_PLAYBACK_SPEED,
    default: 2,
  },
  FLAG_NOT_LOGGED_IN_MODE_ENABLED_DEFAULT: {
    value: bool(process.env.REACT_APP_FLAG_NOT_LOGGED_IN_MODE_ENABLED_DEFAULT),
    default: false,
  },
  CONTENT_PACKAGE_PERMISSIONS_ENABLED: {
    value: bool(process.env.REACT_APP_CONTENT_PACKAGE_PERMISSIONS_ENABLED),
    default: false,
  },
};
